<template>
  <div class="multiselect-wrap-component">
    <Multiselect
      v-model="bindingModel"
      :value="bindingModel"
      :options="options"
      :multiple="multiple"
      :track-by="trackBy"
      :close-on-select="closeOnSelect"
      :clear-on-select="clearOnSelect"
      :searchable="searchable"
      :show-labels="showLabels"
      :showNoResults="showNoResults"
      :showPointer="showPointer"
      :placeholder="bindingPlaceholder"
      :block-keys="blockKeys"
      :internal-search="internalSearch"
      :custom-label="searchCustomLabel"
      @select="onSelected"
      @remove="onRemoved"
      @search-change="onInputChange"
      :disabled="disabled"
    >
      <template slot="tag">{{ '' }}</template>
      <template
        slot="selection"
        slot-scope="{ values, isOpen }"
        style="display: flex; aign-items: center"
      >
        <span class="multiselect__single" v-if="values.length && !isOpen">
          {{
            values.map((item) => item.id).includes(-1)
              ? values.length - 1
              : values.length
          }}
          {{ placeholder }} đã chọn</span
        >
      </template>
      <div slot-scope="scope" slot="option">
        <slot name="custom-option" :scope="scope">
          <div class="multiselect__checkbox_name">
            {{ scope.option[suggestionName] }}
            <input
              class="multiselect__checkbox"
              type="checkbox"
              v-model="scope.option.checked"
            />
          </div>
        </slot>
      </div>
      <span style="font-size: 12px" slot="noResult">Không có kết quả</span>
    </Multiselect>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
  components: {
    Multiselect,
  },
  props: {
    model: {
      type: Array,
      default: () => {
        return [];
      },
    },
    options: {
      type: Array,
      default: () => {
        return [];
      },
      required: true,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    closeOnSelect: {
      type: Boolean,
      default: false,
    },
    clearOnSelect: {
      type: Boolean,
      default: true,
    },
    selectAllOption: {
      type: Boolean,
      default: true,
    },
    searchable: {
      type: Boolean,
      default: true,
    },
    showLabels: {
      type: Boolean,
      default: false,
    },
    showPointer: {
      type: Boolean,
      default: false,
    },
    showNoResults: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: 'cửa hàng',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    blockKeys: {
      type: Array,
      default: () => {
        return ['Tab', 'Enter'];
      },
    },
    internalSearch: {
      type: Boolean,
      default: false,
    },
    trackBy: {
      type: String,
      default: 'id',
    },
    customLabel: {
      default: () => {
        return ['name'];
      },
    },
    suggestionName: {
      type: String,
      default: 'name',
    },
  },
  data() {
    return {
      bindingPlaceholder: 'Nhập thông tin',
    };
  },
  computed: {
    bindingModel: {
      get: function () {
        return this.model;
      },
      set: function (newValue) {
        if (newValue) {
          this.$emit('update:model', newValue);
        }
        return;
      },
    },
  },
  created() {
    this.onInitial();
  },
  methods: {
    onInitial() {
      if (this.placeholder) {
        this.bindingPlaceholder = `Chọn ${this.placeholder}`;
      }

      if (this.disabled && this.isEmpty(this.bindingModel)) {
        this.bindingPlaceholder = ` Chưa gán ${this.placeholder} `;
      }
    },
    onSelected(option) {
      if (this.selectAllOption) {
        this.$emit('selectAll', option);
      } else {
        this.$emit('select', option);
      }
    },
    onRemoved(option) {
      if (this.selectAllOption) {
        this.$emit('removeAll', option);
      } else {
        this.$emit('remove', option);
      }
    },
    onInputChange(searchQuery = '') {
      this.$emit('searchChange', searchQuery);
    },
    isEmpty(value) {
      return !value || !value.length;
    },
    searchCustomLabel(option) {
      return this.customLabel.reduce((result, key) => {
        if (key && option[key]) return (result += option[key]);
      }, '');
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
.multiselect-wrap-component {
  .multiselect__checkbox {
    position: absolute;
    right: 0.3vw;
  }

  .multiselect__checkbox [type='checkbox'][readonly='readonly']::before {
    content: '';
  }
  .multiselect__checkbox_name {
    display: block;
    overflow: hidden;
  }

  .multiselect__checkbox {
    vertical-align: middle;
    float: right;
  }
  .multiselect__tags {
    height: calc(1.35em + 1.1rem + 2px);
    display: block;
    border-radius: 0.28rem;
    border: 1px solid #ced4da;
    font-size: 0.925rem;
    min-height: initial;
    padding-top: 0.3rem;
  }

  .multiselect__option {
    padding: 5px;
    padding-top: 8px;
    min-height: 30px;
  }

  .multiselect__select {
    width: 3rem;
    height: 2.5rem;
    top: 0.1rem;
  }

  .multiselect__placeholder {
    color: #495057;
    padding-left: 0.2rem;
  }

  .multiselect__element {
    font-size: 0.925rem;
  }

  .multiselect__input {
    padding-top: 0.2rem;
    font-size: 0.925rem;
  }

  .multiselect__single {
    font-size: 0.925rem;
    line-height: 1.9;
    padding-left: 0.2rem;
    display: inline-block;
    max-width: 100%;
  }
}
</style>
